<form #dataForm="ngForm" style="height: 100%">
  <div class="main-container">
    <app-booking-stepper></app-booking-stepper>
    <app-title-with-languages [title]="'ADDREASON_TITLE'"></app-title-with-languages>
    <div class="inner-container d-flex flex-column justify-content-start overflow-y mt-2 mb-3 px-3">
      <div class="dm-container add-reason-content mt-0">
        <div class="form-group mb-1">
          <label>
            {{ "EDIT_REASON_SELECT" | translate }}
            {{ " *" }}
          </label>
          <ng-select
            [items]="editReasonSelectTypes!"
            class="form-control p-0"
            [(ngModel)]="bookingService.reasonTypeCode"
            [ngClass]="{ 'is-invalid': truckType.invalid }"
            [searchable]="false"
            #truckType="ngModel"
            name="truckType"
            bindLabel="name"
            bindValue="code"
            autocomplete="off"
            [required]="false"
          >
            <ng-template ng-option-tmp let-item="item">
              {{ item.name }}
            </ng-template>
          </ng-select>
        </div>
        <div class="form-group">
          <label>
            {{ "EDIT_REASON" | translate }}
          </label>
          <textarea
            name="reasonInput"
            class="form-control"
            [(ngModel)]="bookingService.reason"
            #reasonInput="ngModel"
            [ngClass]="{ 'is-invalid': reasonInput.invalid }"
            [required]="false"
            maxlength="250"
            autocomplete="off"
            rows="3"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="inner-footer">
      <div class="alert alert-danger" role="alert" *ngIf="error">
        {{ error }}
      </div>
      <div class="dm-container">
        <button class="btn btn-primary footer-button" (click)="goBack()">
          <i class="bi bi-arrow-left"></i>
          <span>{{ "STEPPER_PREV" | translate }}</span>
        </button>
        <button class="btn btn-primary footer-button" (click)="goNext($event)" [disabled]="!bookingService.reasonTypeCode">
          <i class="bi bi-check-lg"></i>
          <span> {{ "STEPPER_COMPLETE" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
</form>
