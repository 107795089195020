import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { NgbActiveModal } from "@ng-bootstrap/ng-bootstrap";
import { CustomPrivacyLMApi } from "src/app/api";
import { BookingService } from "src/app/services/booking.service";

@Component({
  selector: "app-privacy-company-dialog",
  template: `
    <div class="modal-header">
      <h3 class="modal-title">
        {{ "PRIVACY_COMPANY_TITLE" | translate }}
      </h3>
    </div>
    <div class="modal-body">
      <div *ngIf="privacyText || privacyLink">
        <div>
          {{ privacyText }}
        </div>
        <a [href]="privacyLink" *ngIf="privacyLink" target="_blank" translate="" class="ng-scope">Privacy link</a>
        <hr class="mt-3 mb-3" />
      </div>
      <div *ngIf="cookieText || cookieLink">
        <div>
          {{ cookieText }}
        </div>
        <a [href]="cookieLink" *ngIf="cookieLink" target="_blank" translate="" class="ng-scope">Cookie link</a>
        <hr class="mt-3 mb-3" />
      </div>
      <div class="form-check d-flex justify-content-end">
        <input class="form-check-input me-2" style="min-height: 16px" type="checkbox" id="flexCheckDefault" [(ngModel)]="checkBox" />
        <label class="form-check-label" for="flexCheckDefault"> {{ "PRIVACY_COMPANY_CHECK_MESSAGE" | translate }} </label>
      </div>
    </div>
    <div class="modal-footer justify-content-between">
      <button type="button" class="btn btn-secondary btn-50-mobile" aria-label="Close" (click)="activeModal.dismiss()">
        {{ "DIALOG_CLOSE" | translate }}
      </button>
      <button type="button" class="btn btn-primary btn-50-mobile" style="color: white;" aria-label="Close" [disabled]="!checkBox" (click)="activeModal.close(true)">
        {{ "DIALOG_COMPLETE" | translate }}
      </button>
    </div>
  `,
})
export class PrivacyCompanyDialogComponent implements OnInit {
  @Input() customPrivacy!: CustomPrivacyLMApi;
  privacyText: string | null | undefined;
  privacyLink: string | null | undefined;
  cookieText: string | null | undefined;
  cookieLink: string | null | undefined;
  checkBox: boolean = false;

  constructor(public activeModal: NgbActiveModal, private bookingService: BookingService) {}
  ngOnInit(): void {
    this.bookingService.$lang.subscribe((lang) => {
      if (lang === "it") {
        this.privacyText = this.customPrivacy.privacyIt;
        this.privacyLink = this.customPrivacy.privacyLinkIt;
        this.cookieText = this.customPrivacy.cookieIt;
        this.cookieLink = this.customPrivacy.cookieLinkIt;
      } else {
        this.privacyText = this.customPrivacy.privacyEn;
        this.privacyLink = this.customPrivacy.privacyLinkEn;
        this.cookieText = this.customPrivacy.cookieEn;
        this.cookieLink = this.customPrivacy.cookieLinkEn;
      }
    });
  }
}
