<form #dataForm="ngForm" style="height: 100%">
  <!-- ------------------ LOADING ------------------ -->
  <div class="main-container pt-3" *ngIf="!loaded">
    <app-booking-stepper></app-booking-stepper>
    <div class="inner-container overflow-y mt-3">
      <div class="dm-container">
        <div class="d-flex flex-column align-items-center">
          <div class="spinner-border mb-3" style="width: 4rem; height: 4rem" role="status"></div>
          {{ "LOADING_DATA" | translate }}
        </div>
      </div>
    </div>
    <div class="inner-footer" [hidden]="(bookingService.isScreenSmall$ | async) || (!(bookingService.isScreenSmall$ | async) && bookingService.desktopStep == 1)">
      <div class="dm-container">
        <button class="btn btn-primary footer-button" (click)="goBack()" *ngIf="!bookingService.isInjected">
          <i class="bi bi-arrow-left"></i>
          <span>{{ "STEPPER_PREV" | translate }}</span>
        </button>
        <button class="btn btn-primary footer-button" [disabled]="true">
          <i class="bi bi-arrow-right"></i>
          <span>{{ "STEPPER_NEXT" | translate }}</span>
        </button>
      </div>
    </div>
    <div class="inner-footer" [hidden]="!(bookingService.isScreenSmall$ | async) && bookingService.desktopStep == 0">
      <div class="dm-container">
        <button class="btn btn-primary footer-button" (click)="goBackWithDesktopScreenMode()">
          <i class="bi bi-arrow-left"></i>
          <span>{{ "STEPPER_PREV" | translate }}</span>
        </button>
        <button class="btn btn-primary footer-button" [disabled]="true">
          <i class="bi bi-arrow-right"></i>
          <span>{{ "STEPPER_NEXT" | translate }}</span>
        </button>
      </div>
    </div>
  </div>
  <!-- ------------------ END LOADING ------------------ -->

  <!-- ------------------ REJECT OR DELETE ------------------ -->
  <div class="main-container" *ngIf="bookingService.canEditResponse?.rejected || bookingService.canEditResponse?.deleted">
    <div class="inner-container overflow-y mt-5 px-3 pb-3 text-break">
      <div class="bookingcode-edit-section d-flex flex-column flex-lg-row justify-content-between">
        <div class="alert alert-danger d-flex flex-row text-break w-100">
          <i class="bi bi-exclamation-triangle-fill me-3"></i>
          <div>
            <b>
              {{ bookingService.canEditResponse?.rejected ? ("CANT_EDIT_REJECTED" | translate) : bookingService.canEditResponse?.deleted ? ("CANT_EDIT_DELETED" | translate) : "" }}
            </b>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ------------------ END REJECT OR DELETE ------------------ -->

  <!-- ------------------ MAIN ------------------ -->
  <div
    class="main-container"
    [hidden]="!loaded || !model || !configuration || !mandatoryFieldsActive || !typeActive || bookingService.canEditResponse?.rejected || bookingService.canEditResponse?.deleted"
  >
    <app-booking-stepper></app-booking-stepper>
    <div class="inner-container overflow-y px-3 pb-3 text-break">
      <app-title-with-languages [title]="'EDIT_TITLE'" [hidden]="!(bookingService.isScreenSmall$ | async) && bookingService.desktopStep == 1"></app-title-with-languages>
      <div class="dm-container">
        <div [hidden]="!(bookingService.isScreenSmall$ | async) && bookingService.desktopStep == 1">
          <div class="text-center">
            <h5>
              <b>{{ model.code }} - {{ bookingService.editInitialBookingDate | dateToPlantTimezone : "EEE dd MMM yyyy - HH:mm" : configuration?.timezone | async | uppercase }}</b>
            </h5>
          </div>
          <div class="d-flex flex-column mt-2">
            <div class="alert alert-disabled d-flex justify-content-between" role="alert" *ngIf="selectedWarehouseData">
              <div>
                <div>
                  <b>{{ selectedWarehouseData.description }}</b>
                </div>
                <div>{{ selectedWarehouseData.address }}</div>
                <div>{{ selectedWarehouseData.cap }} {{ selectedWarehouseData.city }}</div>
              </div>
              <div class="d-flex flex-column align-items-end">
                <b class="text-end">{{ "DATA_PLANT_HOURS" | translate }}:</b>
                <div>{{ now | dateToPlantTimezone : "yyyy-MM-dd HH:mm" : configuration?.timezone | async }}</div>
              </div>
            </div>

            <div
              class="bookingcode-edit-section d-flex flex-column flex-lg-row justify-content-between"
              *ngIf="
                !bookingService.canEditResponse?.canEditMandatory ||
                bookingService.canEditResponse?.rejected ||
                bookingService.canEditResponse?.deleted ||
                bookingService.canEditResponse?.entrance ||
                bookingService.canEditResponse?.completed
              "
            >
              <div class="alert alert-danger d-flex flex-row text-break w-100">
                <i class="bi bi-exclamation-triangle-fill me-3"></i>
                <div>
                  <b>{{
                    bookingService.canEditResponse?.canEditMandatory
                      ? ("CANT_EDIT_MANDATORY" | translate)
                      : bookingService.canEditResponse?.entrance
                      ? ("CANT_EDIT_ENTRACE" | translate)
                      : bookingService.canEditResponse?.completed
                      ? ("CANT_EDIT_COMPLETED" | translate)
                      : ("CANT_EDIT_OLD" | translate)
                  }}</b>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column flex-lg-row justify-content-between gap-lg-5">
              <div class="form-group w-100 w-lg-50" *ngIf="configuration?.truckType?.visible">
                <label>
                  {{ configuration?.truckType?.labelTranslations | translateLabel | async }}
                  {{ configuration?.truckType?.required ? " *" : "" }}
                </label>
                <ng-select
                  [items]="configuration?.truckType?.types!"
                  class="form-control p-0 edit-select-truck-type"
                  [(ngModel)]="model.truckType"
                  [ngClass]="{ 'is-invalid  border border-danger border-3': truckType.invalid }"
                  [searchable]="false"
                  #truckType="ngModel"
                  name="truckType"
                  bindLabel="name"
                  [disabled]="!bookingService.canEditResponse?.canEditMandatory"
                  bindValue="code"
                  autocomplete="off"
                  [required]="configuration?.truckType?.required!"
                >
                  <ng-template ng-option-tmp let-item="item">
                    <app-img-base64 [base64string]="item.icon"></app-img-base64>
                    {{ item.name }}
                  </ng-template>
                </ng-select>
              </div>
              <div class="form-group w-100 w-lg-50" *ngIf="configuration?.email?.visible">
                <label>
                  {{ configuration?.email?.labelTranslations | translateLabel | async }}
                  {{ configuration?.email?.required ? " *" : "" }}
                </label>
                <input
                  type="email"
                  class="form-control"
                  name="email"
                  [(ngModel)]="model.email"
                  #email="ngModel"
                  [ngClass]="{ 'is-invalid  border border-danger border-3': email.invalid }"
                  email
                  [disabled]="true"
                  [autocomplete]="false"
                  [required]="configuration?.email?.required!"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="section-activity mb-3 mt-3" [hidden]="!(bookingService.isScreenSmall$ | async) && bookingService.desktopStep == 1">
          <button class="btn btn-primary" (click)="addProduct()" *ngIf="bookingService.canEditResponse?.canEditMandatory">
            <i class="bi bi-plus-lg me-2"></i>{{ "DATA_ADD_PRODUCT" | translate }}
          </button>
          <div class="mobile">
            <div
              *ngFor="let data of model?.products; let index = index"
              class="card mt-2"
              [ngClass]="bookingService.canEditResponse?.canEditMandatory ? '' : 'product-card-disabled'"
            >
              <div class="card-body">
                <div class="d-flex flex-row" *ngIf="configuration?.type?.visible" [ngClass]="!data.action ? 'product-undefined' : ''">
                  <div class="me-2 fw-bold">{{ configuration?.type?.labelTranslations | translateLabel | async }}:</div>
                  <div class="text-trim">{{ data.action ? (data.action | getNameFromCode : configuration?.type?.types) : ("VIEW_MISSING_VALUE" | translate) }}</div>
                </div>
                <div class="d-flex flex-row" *ngIf="configuration?.product?.visible" [ngClass]="!data.code ? 'product-undefined' : ''">
                  <div class="me-2 fw-bold">{{ configuration?.product?.labelTranslations | translateLabel | async }}:</div>
                  <div class="text-trim">{{ data.code ? (data.code | getNameFromCode : configuration?.product?.types) : ("VIEW_MISSING_VALUE" | translate) }}</div>
                </div>
                <div class="d-flex flex-row" *ngIf="configuration?.quantity?.visible" [ngClass]="!data.quantity ? 'product-undefined' : ''">
                  <div class="me-2 fw-bold">{{ configuration?.quantity?.labelTranslations | translateLabel | async }}:</div>
                  <div class="text-trim">{{ data.quantity ? data.quantity : ("VIEW_MISSING_VALUE" | translate) }}</div>
                </div>
                <div class="d-flex flex-row" *ngIf="configuration?.deliveryMethod?.visible" [ngClass]="!data.deliveryMethod ? 'product-undefined' : ''">
                  <div class="me-2 fw-bold">{{ configuration?.deliveryMethod?.labelTranslations | translateLabel | async }}:</div>
                  <div class="text-trim">
                    {{ data.deliveryMethod ? (data.deliveryMethod | getNameFromCode : configuration?.deliveryMethod?.types) : ("VIEW_MISSING_VALUE" | translate) }}
                  </div>
                </div>
                <div class="d-flex flex-row" *ngIf="configuration?.sourceDestination?.visible" [ngClass]="!data.sourceDestination ? 'product-undefined' : ''">
                  <div class="me-2 fw-bold">{{ configuration?.sourceDestination?.labelTranslations | translateLabel | async }}:</div>
                  <div class="text-trim">
                    {{ data.sourceDestination ? (data.sourceDestination | getNameFromCode : configuration?.sourceDestination?.types) : ("VIEW_MISSING_VALUE" | translate) }}
                  </div>
                </div>
                <div class="pt-3 d-flex flex-row align-items-stretch" *ngIf="bookingService.canEditResponse?.canEditMandatory">
                  <button class="btn btn-danger w-50" (click)="removeProduct(remove, index)">
                    <i class="bi bi-trash-fill me-2"></i>
                    {{ "DATA_TABLE_DELETE" | translate }}
                  </button>
                  <button class="btn btn-primary w-50" (click)="editProduct(data, index)">
                    <i class="bi bi-pen-fill me-2"></i>
                    {{ "DATA_TABLE_EDIT" | translate }}
                  </button>
                </div>
              </div>
            </div>
            <div
              class="border border-danger rounded d-flex flex-column align-items-center justify-content-center"
              style="height: 52px"
              *ngIf="!model.products || (model.products && model.products.length == 0)"
            >
              {{ "NO_PRODUCT_DATA" | translate }}
            </div>
          </div>
          <div class="desktop">
            <form #dataForm="ngForm">
              <table class="table table-borderless me-2 products-table">
                <thead>
                  <tr>
                    <th class="border-bottom-0" scope="col" *ngIf="typeActive?.visible" style="width: 15%">
                      {{ typeActive?.labelTranslations | translateLabel | async }} {{ typeActive?.required ? " *" : "" }}
                    </th>
                    <th class="border-bottom-0" scope="col" *ngIf="configuration?.product?.visible" style="width: 30%">
                      {{ configuration?.product?.labelTranslations | translateLabel | async }}
                      {{ configuration?.product?.required ? " *" : "" }}
                    </th>
                    <th class="border-bottom-0" scope="col" *ngIf="configuration?.quantity?.visible" style="width: 10%">
                      {{ configuration?.quantity?.labelTranslations | translateLabel | async }}
                      {{ configuration?.quantity?.required ? " *" : "" }}
                    </th>
                    <th class="border-bottom-0" scope="col" *ngIf="configuration?.deliveryMethod?.visible" style="width: 20%">
                      {{ configuration?.deliveryMethod?.labelTranslations | translateLabel | async }}
                      {{ configuration?.deliveryMethod?.required ? " *" : "" }}
                    </th>
                    <th class="border-bottom-0" scope="col" *ngIf="configuration?.sourceDestination?.visible" style="flex-grow: 1">
                      {{ configuration?.sourceDestination?.labelTranslations | translateLabel | async }}
                      {{ configuration?.sourceDestination?.required ? " *" : "" }}
                    </th>
                    <th class="border-bottom-0" scope="col" style="width: 50px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    *ngFor="let product of model.products!; let index = index"
                    app-add-product
                    [configuration]="configuration!"
                    [canEdit]="bookingService.canEditResponse?.canEditMandatory"
                    [typeActive]="typeActive"
                    [product]="product"
                    (removeProduct)="removeProduct(remove, index)"
                    (updateProduct)="changeConfiguration()"
                  ></tr>
                  <tr class="table-no-data border border-danger" style="height: 52px" *ngIf="!model.products || (model.products && model.products.length == 0)">
                    <td colspan="6" class="align-middle">{{ "NO_PRODUCT_DATA" | translate }}</td>
                  </tr>
                </tbody>
              </table>
            </form>
          </div>
        </div>

        <div class="section-extra" [hidden]="!(bookingService.isScreenSmall$ | async) && bookingService.desktopStep == 0">
          <h3 class="inner-title">
            {{ "DATA_TITLE_EXTRA" | translate }}
          </h3>
          <div class="d-flex flex-column flex-lg-row justify-content-between gap-lg-5">
            <div class="d-flex flex-column w-100 w-lg-50">
              <div class="form-group" [hidden]="!mandatoryFieldsActive?.driverPhone?.visible">
                <label>
                  {{ mandatoryFieldsActive?.driverPhone?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.driverPhone?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.driverPhone?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <div class="d-flex flex-column">
                  <input
                    type="tel"
                    #driverPhoneInput
                    id="driverPhoneInput"
                    name="driverPhone"
                    class="form-control"
                    [disabled]="!bookingService.canEditResponse?.canEditOptional"
                    [placeholder]="' '"
                    [required]="mandatoryFieldsActive?.driverPhone?.required!"
                    autocomplete="off"
                    [ngClass]="{ 'is-invalid  border border-danger border-3': !mandatoryFieldsActive?.driverPhone?.required && emptyPhoneNumber ? false : !intlValid }"
                  />
                </div>
              </div>
              <div class="form-group" *ngIf="mandatoryFieldsActive?.carrier?.visible">
                <label>
                  {{ mandatoryFieldsActive?.carrier?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.carrier?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.carrier?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <input
                  type="text"
                  name="carrier"
                  class="form-control text-uppercase"
                  [(ngModel)]="model.carrier"
                  [disabled]="!bookingService.canEditResponse?.canEditOptional"
                  #carrier="ngModel"
                  [ngClass]="{ 'is-invalid  border border-danger border-3': carrier.invalid }"
                  [required]="mandatoryFieldsActive?.carrier?.required!"
                  autocomplete="off"
                />
              </div>
              <div class="form-group" *ngIf="mandatoryFieldsActive?.refName?.visible">
                <label>
                  {{ mandatoryFieldsActive?.refName?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.refName?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.refName?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <input
                  type="text"
                  name="refName"
                  class="form-control text-uppercase"
                  [(ngModel)]="model.referenceName"
                  [disabled]="!bookingService.canEditResponse?.canEditOptional"
                  #refName="ngModel"
                  [ngClass]="{
                    'is-invalid  border border-danger border-3': refName.invalid
                  }"
                  [required]="mandatoryFieldsActive?.refName?.required!"
                  autocomplete="off"
                />
              </div>
              <div class="form-group" *ngIf="mandatoryFieldsActive?.refNumber?.visible">
                <label>
                  {{ mandatoryFieldsActive?.refNumber?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.refNumber?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.refNumber?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <input
                  type="text"
                  name="refNumber"
                  class="form-control text-uppercase"
                  [(ngModel)]="model.referenceNumber"
                  [disabled]="!bookingService.canEditResponse?.canEditOptional"
                  #refNumber="ngModel"
                  [ngClass]="{ 'is-invalid border border-danger border-3': refNumber.invalid }"
                  [required]="mandatoryFieldsActive?.refNumber?.required!"
                  autocomplete="off"
                />
              </div>
              <div class="form-group" *ngIf="mandatoryFieldsActive?.secRefNumber?.visible">
                <label>
                  {{ mandatoryFieldsActive?.secRefNumber?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.secRefNumber?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.secRefNumber?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <input
                  type="text"
                  name="secRefNumber"
                  class="form-control text-uppercase"
                  [(ngModel)]="model.secReferenceNumber"
                  [disabled]="!bookingService.canEditResponse?.canEditOptional"
                  #secRefNumber="ngModel"
                  [ngClass]="{ 'is-invalid border border-danger border-3': secRefNumber.invalid }"
                  [required]="mandatoryFieldsActive?.secRefNumber?.required!"
                  autocomplete="off"
                />
              </div>
              <div class="form-group" *ngIf="mandatoryFieldsActive?.truckPlate?.visible">
                <label>
                  {{ mandatoryFieldsActive?.truckPlate?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.truckPlate?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.truckPlate?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <input
                  type="text"
                  name="truckPlate"
                  class="form-control text-uppercase"
                  [(ngModel)]="model.truckPlate"
                  [disabled]="!bookingService.canEditResponse?.canEditOptional"
                  #truckPlate="ngModel"
                  [ngClass]="{ 'is-invalid border border-danger border-3': truckPlate.invalid }"
                  [required]="mandatoryFieldsActive?.truckPlate?.required!"
                  autocomplete="off"
                />
              </div>
              <div class="form-group" *ngIf="mandatoryFieldsActive?.trailerPlate?.visible">
                <label>
                  {{ mandatoryFieldsActive?.trailerPlate?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.trailerPlate?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.trailerPlate?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <input
                  type="text"
                  name="trailerPlate"
                  class="form-control text-uppercase"
                  [(ngModel)]="model.trailerPlate"
                  [disabled]="!bookingService.canEditResponse?.canEditOptional"
                  #trailerPlate="ngModel"
                  [ngClass]="{ 'is-invalid border border-danger border-3': trailerPlate.invalid }"
                  [required]="mandatoryFieldsActive?.trailerPlate?.required!"
                  autocomplete="off"
                />
              </div>
            </div>
            <div class="d-flex flex-column w-100 w-lg-50">
              <div class="form-group" *ngIf="mandatoryFieldsActive?.sender?.visible">
                <label>
                  {{ mandatoryFieldsActive?.sender?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.sender?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.sender?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <input
                  type="text"
                  name="sender"
                  class="form-control text-uppercase"
                  [(ngModel)]="model.sender"
                  [disabled]="!bookingService.canEditResponse?.canEditOptional"
                  #sender="ngModel"
                  [ngClass]="{ 'is-invalid border border-danger border-3': sender.invalid }"
                  [required]="mandatoryFieldsActive?.sender?.required!"
                  autocomplete="off"
                />
              </div>
              <div class="form-group" *ngIf="mandatoryFieldsActive?.receiver?.visible">
                <label>
                  {{ mandatoryFieldsActive?.receiver?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.receiver?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.receiver?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <input
                  type="text"
                  name="receiver"
                  class="form-control text-uppercase"
                  [(ngModel)]="model.receiver"
                  [disabled]="!bookingService.canEditResponse?.canEditOptional"
                  #receiver="ngModel"
                  [ngClass]="{ 'is-invalid border border-danger border-3': receiver.invalid }"
                  [required]="mandatoryFieldsActive?.receiver?.required!"
                  autocomplete="off"
                />
              </div>
              <div class="form-group" *ngIf="mandatoryFieldsActive?.note?.visible">
                <label>
                  {{ mandatoryFieldsActive?.note?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.note?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.note?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <textarea
                  name="note"
                  class="form-control text-uppercase"
                  [(ngModel)]="model.notes"
                  [disabled]="!bookingService.canEditResponse?.canEditOptional"
                  #note="ngModel"
                  [ngClass]="{ 'is-invalid border border-danger border-3': note.invalid }"
                  [required]="mandatoryFieldsActive?.note?.required!"
                  autocomplete="off"
                  rows="3"
                ></textarea>
              </div>
              <div class="form-group" *ngIf="mandatoryFieldsActive?.attachments1?.visible">
                <label>
                  {{ mandatoryFieldsActive?.attachments1?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.attachments1?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.attachments1?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <div
                  class="input-group custom-file-button"
                  [ngClass]="{
                    'custom-invalid':
                      mandatoryFieldsActive?.attachments1?.required &&
                      (!attachmentsFileList || (attachmentsFileList && attachmentsFileList[0] && attachmentsFileList[0].length == 0))
                  }"
                  *ngIf="bookingService.canEditResponse?.canEditOptional"
                >
                  <label class="input-group-text" for="inputGroupFile" (click)="file1.click()">
                    <i class="bi bi-paperclip"></i>
                  </label>
                  <input
                    id="file1"
                    multiple
                    class="form-control"
                    type="file"
                    name="attachmentInput"
                    class="form-control"
                    (change)="uploadFile($event, 0)"
                    [required]="mandatoryFieldsActive?.attachments1?.required!"
                    autocomplete="off"
                    value=""
                    #file1
                    hidden
                  />
                  <label class="form-control d-flex flex-row align-items-center" for="file1">
                    {{ "DATA_SELECTFILE" | translate }}
                  </label>
                </div>
              </div>
              <div *ngIf="loadingAttachments[0]"><div class="spinner-border mb-3" style="width: 2rem; height: 2rem" role="status"></div></div>
              <div class="d-flex flex-column">
                <div *ngFor="let a of attachmentsFileList && attachmentsFileList[0]" class="d-flex flex-row chip justify-content-between">
                  <div class="chip-icon-left"><i class="bi bi-paperclip"></i></div>
                  <label class="chip-label flex-grow-1">{{ a.fileName }}</label>
                  <div class="chip-icon-right" (click)="downloadFile(a)"><i class="bi bi-file-earmark-arrow-down-fill"></i></div>
                  <div class="chip-icon-right" (click)="deleteFile(a, 0)"><i class="bi bi-trash-fill"></i></div>
                </div>
              </div>
              <div class="alert alert-danger mt-2" role="alert" *ngIf="attachmentsFileErrors && attachmentsFileErrors[0] && attachmentsFileErrors[0].length > 0">
                <div *ngFor="let a of attachmentsFileErrors[0]">{{ a }}</div>
              </div>
              <div class="form-group" *ngIf="mandatoryFieldsActive?.attachments2?.visible">
                <label>
                  {{ mandatoryFieldsActive?.attachments2?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.attachments2?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.attachments2?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <div
                  class="input-group custom-file-button"
                  [ngClass]="{
                    'custom-invalid':
                      mandatoryFieldsActive?.attachments2?.required &&
                      (!attachmentsFileList || (attachmentsFileList && attachmentsFileList[1] && attachmentsFileList[1].length == 0))
                  }"
                  *ngIf="bookingService.canEditResponse?.canEditOptional"
                >
                  <label class="input-group-text" for="inputGroupFile" (click)="file2.click()">
                    <i class="bi bi-paperclip"></i>
                  </label>
                  <input
                    id="file2"
                    multiple
                    class="form-control"
                    type="file"
                    name="attachmentInput"
                    class="form-control"
                    (change)="uploadFile($event, 1)"
                    [required]="mandatoryFieldsActive?.attachments2?.required!"
                    autocomplete="off"
                    value=""
                    #file2
                    hidden
                  />
                  <label class="form-control d-flex flex-row align-items-center" for="file2">
                    {{ "DATA_SELECTFILE" | translate }}
                  </label>
                </div>
              </div>
              <div *ngIf="loadingAttachments[1]"><div class="spinner-border mb-3" style="width: 2rem; height: 2rem" role="status"></div></div>
              <div class="d-flex flex-column">
                <div *ngFor="let a of attachmentsFileList && attachmentsFileList[1]" class="d-flex flex-row chip justify-content-between">
                  <div class="chip-icon-left"><i class="bi bi-paperclip"></i></div>
                  <label class="chip-label flex-grow-1">{{ a.fileName }}</label>
                  <div class="chip-icon-right" (click)="downloadFile(a)"><i class="bi bi-file-earmark-arrow-down-fill"></i></div>
                  <div class="chip-icon-right" (click)="deleteFile(a, 1)"><i class="bi bi-trash-fill"></i></div>
                </div>
              </div>
              <div class="alert alert-danger mt-2" role="alert" *ngIf="attachmentsFileErrors && attachmentsFileErrors[1] && attachmentsFileErrors[1].length > 0">
                <div *ngFor="let a of attachmentsFileErrors[1]">{{ a }}</div>
              </div>
              <div class="form-group" *ngIf="mandatoryFieldsActive?.attachments3?.visible">
                <label>
                  {{ mandatoryFieldsActive?.attachments3?.labelTranslations | translateLabel | async }}
                  {{ mandatoryFieldsActive?.attachments3?.required ? " *" : "" }}
                  <i
                    class="bi bi-info-circle"
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    [title]="mandatoryFieldsActive?.attachments3?.tooltipTranslations | translateLabel | async"
                  ></i>
                </label>
                <div
                  class="input-group custom-file-button"
                  [ngClass]="{
                    'custom-invalid':
                      mandatoryFieldsActive?.attachments3?.required &&
                      (!attachmentsFileList || (attachmentsFileList && attachmentsFileList[2] && attachmentsFileList[2].length == 0))
                  }"
                  *ngIf="bookingService.canEditResponse?.canEditOptional"
                >
                  <label class="input-group-text" for="inputGroupFile" (click)="file3.click()">
                    <i class="bi bi-paperclip"></i>
                  </label>
                  <input
                    id="file3"
                    multiple
                    class="form-control"
                    type="file"
                    name="attachmentInput"
                    class="form-control"
                    (change)="uploadFile($event, 2)"
                    [required]="mandatoryFieldsActive?.attachments3?.required!"
                    autocomplete="off"
                    value=""
                    #file3
                    hidden
                  />
                  <label class="form-control d-flex flex-row align-items-center" for="file3">
                    {{ "DATA_SELECTFILE" | translate }}
                  </label>
                </div>
              </div>
              <div *ngIf="loadingAttachments[2]"><div class="spinner-border mb-3" style="width: 2rem; height: 2rem" role="status"></div></div>
              <div class="d-flex flex-column">
                <div *ngFor="let a of attachmentsFileList && attachmentsFileList[2]" class="d-flex flex-row chip justify-content-between">
                  <div class="chip-icon-left"><i class="bi bi-paperclip"></i></div>
                  <label class="chip-label flex-grow-1">{{ a.fileName }}</label>
                  <div class="chip-icon-right" (click)="downloadFile(a)"><i class="bi bi-file-earmark-arrow-down-fill"></i></div>
                  <div class="chip-icon-right" (click)="deleteFile(a, 2)"><i class="bi bi-trash-fill"></i></div>
                </div>
              </div>
              <div class="alert alert-danger mt-2" role="alert" *ngIf="attachmentsFileErrors && attachmentsFileErrors[2] && attachmentsFileErrors[2].length > 0">
                <div *ngFor="let a of attachmentsFileErrors[2]">{{ a }}</div>
              </div>
              <button
                class="btn btn-danger w-100 mt-3 mb-3"
                (click)="deleteShipment()"
                *ngIf="bookingService.canEditResponse?.canDelete && (bookingService.isScreenSmall$ | async)"
              >
                <i class="bi bi-trash-fill me-2"></i>{{ "DELETE_SHIPMENT" | translate }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="inner-footer" [hidden]="(bookingService.isScreenSmall$ | async) || (!(bookingService.isScreenSmall$ | async) && bookingService.desktopStep == 1)">
      <div class="dm-container">
        <button
          class="btn btn-danger footer-delete-button-left"
          (click)="deleteShipment()"
          *ngIf="bookingService.canEditResponse?.canDelete && !(bookingService.isScreenSmall$ | async)"
        >
          <i class="bi bi-trash-fill me-2"></i>{{ "DELETE_SHIPMENT" | translate }}
        </button>
        <button class="btn btn-primary footer-button" (click)="goBack()" *ngIf="!bookingService.isInjected">
          <i class="bi bi-arrow-left"></i>
          <span>{{ "STEPPER_PREV" | translate }}</span>
        </button>
        <button
          class="btn btn-primary footer-button"
          [disabled]="!date || !hour || !model.email || !model.truckType || model.products?.length == 0 || checkProductData()"
          (click)="goNextWithDesktopScreenMode()"
        >
          <i class="bi bi-arrow-right"></i>
          <span>{{ "STEPPER_NEXT" | translate }}</span>
        </button>
      </div>
    </div>
    <div class="inner-footer" [hidden]="!(bookingService.isScreenSmall$ | async) && bookingService.desktopStep == 0">
      <div class="dm-container">
        <button class="btn btn-danger footer-delete-button-left" (click)="deleteShipment()" *ngIf="!(bookingService.isScreenSmall$ | async)">
          <i class="bi bi-trash-fill me-2"></i>{{ "DELETE_SHIPMENT" | translate }}
        </button>
        <button class="btn btn-primary footer-button" (click)="goBackWithDesktopScreenMode()">
          <i class="bi bi-arrow-left"></i>
          <span>{{ "STEPPER_PREV" | translate }}</span>
        </button>
        <button
          class="btn btn-primary footer-button"
          [disabled]="
            dataForm.invalid ||
            model.products?.length == 0 ||
            (!mandatoryFieldsActive?.driverPhone?.required && emptyPhoneNumber ? false : !intlValid) ||
            checkProductData() ||
            (mandatoryFieldsActive?.attachments1?.required &&
              (!attachmentsFileList || attachmentsFileList.length === 0 || (attachmentsFileList[0] && attachmentsFileList[0].length == 0))) ||
            (mandatoryFieldsActive?.attachments2?.required &&
              (!attachmentsFileList || attachmentsFileList.length === 0 || (attachmentsFileList[1] && attachmentsFileList[1].length == 0))) ||
            (mandatoryFieldsActive?.attachments3?.required &&
              (!attachmentsFileList || attachmentsFileList.length === 0 || (attachmentsFileList[2] && attachmentsFileList[2].length == 0))) ||
            !loadedSlot ||
            !bookingService.canEditResponse?.canEditOptional
          "
          (click)="goNext()"
        >
          <ng-container *ngIf="loadedSlot">
            <i class="bi bi-arrow-right"></i>
            <span>{{ "STEPPER_NEXT" | translate }}</span>
          </ng-container>
          <div *ngIf="!loadedSlot" class="h-100 d-flex flex-column justify-content-center align-items-center">
            <div class="spinner-border" role="status"></div>
          </div>
        </button>
      </div>
    </div>
  </div>
  <!-- ------------------ END MAIN ------------------ -->
</form>

<ng-template #remove let-i="index" let-modal>
  <div class="modal-header">
    <h3 class="modal-title" id="modal-basic-title">
      {{ "DIALOG_DELETE_TITLE" | translate }}
    </h3>
  </div>
  <div class="modal-body">
    <p>{{ "DIALOG_DELETE_BODY" | translate }}</p>
  </div>
  <div class="modal-footer justify-content-center p-0">
    <button type="button" class="btn btn-light btn-footer-mobile left" aria-label="Close" (click)="modal.close(false)">
      {{ "DIALOG_DELETE_CANCEL" | translate }}
    </button>
    <button type="button" class="btn btn-primary btn-footer-mobile right" (click)="modal.close(true)">
      {{ "DIALOG_DELETE_CONFIRM" | translate }}
    </button>
  </div>
</ng-template>
